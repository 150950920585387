import React, { useRef, useEffect } from "react";
import classes from "./popup.module.css";

const Popup = props => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.hidePopup(false);
    }
  };

  return (
    <div className={classes.popup} role="list" ref={wrapperRef}>
      {props.children}
    </div>
  );
};

export default Popup;
