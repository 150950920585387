import React, { useState, useContext } from "react";
import { Button, Modal, Form, Checkbox } from "semantic-ui-react";
import AuthContext from "../../context/auth";

const loginModal = () => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    rememberMe: true
  });
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const auth = useContext(AuthContext);

  const emailHandler = event => {
    setLoginForm({ ...loginForm, email: event.target.value });
  };

  const passwordHandler = event => {
    setLoginForm({ ...loginForm, password: event.target.value });
  };

  const doLogin = () => {
    setLoggingIn(true);
    auth
      .submitLogin(loginForm)
      .then(res => {
        setLoggingIn(false);
        setLoginError(res.errors);
      })
      .catch(err => {
        setLoggingIn(false);
        setLoginError(err.errors);
      });
  };

  return (
    <Modal
      size={"mini"}
      open={
        !!(
          (!auth.idToken && !auth.refreshingToken) ||
          (auth.idToken && loggingIn)
        )
      }
      closeOnDimmerClick={false}
    >
      <Modal.Header>Login</Modal.Header>
      <Modal.Content>
        <Form onSubmit={doLogin}>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Email"
            type="email"
            onChange={emailHandler}
            value={loginForm.email}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            onChange={passwordHandler}
            value={loginForm.password}
          />
          <input type="submit" id="submitForm" style={{ display: "none" }} />
        </Form>
        {loginError && (
          <p style={{ color: "red", textAlign: "center" }}>
            Email or Password is invalid.
          </p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Checkbox
          className="rememberCheck"
          label="Remember me"
          checked={loginForm.rememberMe}
          onChange={() =>
            setLoginForm(prevState => ({
              ...prevState,
              rememberMe: !prevState.rememberMe
            }))
          }
        />
        <Button
          primary
          loading={loggingIn}
          onClick={() => {
            document.getElementById("submitForm").click();
          }}
        >
          Login
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default loginModal;
