import React, { useContext, useState } from "react";
import AuthContext from "../../context/auth";
import { Icon, List, Popup } from "semantic-ui-react";
import UserMenu from "../popup/popup";
import classes from "./navbar.module.css";

const navbar = props => {
  const [showAccountOpts, setShowAccountOpts] = useState(false);
  const auth = useContext(AuthContext);
  const changePasswordHandler = () => {
    setShowAccountOpts(false);
    props.setPasswordModalOpen(true);
  };

  const logout = () => {
    setShowAccountOpts(false);
    props.clearItems([]);
    auth.logout();
  };
  return (
    <nav className={classes.navbar}>
      <div>
        <Popup
          content={"Nový checklist"}
          trigger={
            <button
              className={classes.navbutton}
              onClick={props.createChecklist}
            >
              <Icon name="file" size="big" />
            </button>
          }
        />
        <Popup
          content={"Prepni checklist"}
          trigger={
            <button
              className={classes.navbutton}
              onClick={props.changeChecklistModal}
            >
              <Icon name="folder" size="big" />
            </button>
          }
        />
      </div>

      <div style={{ position: "relative" }}>
        <button
          className={classes.navbutton}
          onClick={() => setShowAccountOpts(prev => !prev)}
        >
          <div style={{ paddingTop: "7px" }}>
            {auth.email} <Icon name="triangle down" />
          </div>
        </button>
        {showAccountOpts && (
          <UserMenu hidePopup={setShowAccountOpts}>
            <List link>
              <List.Item as="a" onClick={changePasswordHandler}>
                Zmena hesla
              </List.Item>
              <List.Item as="a" onClick={logout}>
                Odhlásenie
              </List.Item>
            </List>
          </UserMenu>
        )}
      </div>
    </nav>
  );
};

export default navbar;
