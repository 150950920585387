import React, { useState, useEffect } from "react";
import AuthContext from "./context/auth";
import "./App.css";
import axios from "axios";

import Checklist from "./containers/checklist/checklist";

// Global AXIOS Interceptors:
axios.interceptors.request.use(
  cfg => {
    // console.log("Sending request...");
    // console.log(cfg);
    return cfg;
  },
  err => {
    return Promise.reject(err);
  }
);

const App = props => {
  const [auth, setAuth] = useState({
    idToken: null,
    localId: null,
    refreshToken: null,
    expiryDate: null
  });

  const [email, setEmail] = useState(null);
  const [refreshingToken, setRefreshingToken] = useState(false);

  const getEmail = (localId, idToken, rememberMe = false) => {
    let email = localStorage.getItem("uide");
    if (email) {
      email = decodeURIComponent(escape(atob(email)));
      setEmail(email);
    } else {
      axios
        .post(
          `https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyBlYqerYjRa8ZHgymkwGL7pcgNhtv_l-Qw`,
          {
            idToken: idToken
          }
        )
        .then(res => {
          const user = res.data.users[0];
          const uide = btoa(unescape(encodeURIComponent(user.email)));
          if (rememberMe) {
            localStorage.setItem("uide", uide);
          }
          setEmail(user.email);
        });
    }
  };

  const login = authData => {
    const { idToken, localId, refreshToken, expiryDate, rememberMe } = authData;
    if (rememberMe) {
      localStorage.setItem("tkn", idToken);
      localStorage.setItem("expdt", expiryDate);
      localStorage.setItem("uid", localId);
      localStorage.setItem("rfrtkn", refreshToken);
    }
    setAuth({
      idToken,
      localId,
      refreshToken,
      expiryDate
    });
    getEmail(localId, idToken, rememberMe);
  };

  const logout = () => {
    localStorage.removeItem("tkn");
    localStorage.removeItem("expdt");
    localStorage.removeItem("uid");
    localStorage.removeItem("uide");
    localStorage.removeItem("rfrtkn");
    if (refreshingToken) {
      setRefreshingToken(false);
    }
    setAuth({
      idToken: null,
      localId: null,
      refreshToken: null,
      expiryDate: null
    });
    setEmail(null);
  };

  const getNewToken = async (rToken, rememberMe = false) => {
    const payload = {
      grant_type: "refresh_token",
      refresh_token: rToken
    };
    const response = await axios.post(
      `https://securetoken.googleapis.com/v1/token?key=AIzaSyBlYqerYjRa8ZHgymkwGL7pcgNhtv_l-Qw`,
      payload
    );
    const { id_token, user_id, refresh_token, expires_in } = response.data;
    const expiryDate = new Date(new Date().getTime() + expires_in * 1000);
    if (rememberMe) {
      localStorage.setItem("tkn", id_token);
      localStorage.setItem("expdt", expiryDate);
      localStorage.setItem("uid", user_id);
      localStorage.setItem("rfrtkn", refresh_token);
    }
    setAuth({
      idToken: id_token,
      localId: user_id,
      refreshToken: refresh_token,
      expiryDate
    });
    return {
      expiresIn: expires_in,
      rToken: refresh_token,
      rememberMe
    };
  };

  const refreshTokenAfterExpiration = (
    expiresIn,
    rToken,
    rememberMe = false
  ) => {
    // TODO:
    // login aj pw change volaju submit a ten naplanuje refresh, v pamati ostanu 2 refreshe a ten z loginu bude mat neplatny rToken
    // expiresIn je v sekundach
    const f = async () => {
      try {
        setRefreshingToken(true); // zablokujeme GUI
        const newTokenData = await getNewToken(rToken, rememberMe);
        refreshTokenAfterExpiration(
          newTokenData.expiresIn,
          newTokenData.rToken,
          newTokenData.rememberMe
        );
        setRefreshingToken(false);
      } catch (err) {
        logout();
      }
    };
    // Ak je expir cas mensi ako 10 sek volaj rovno necakaj
    if (expiresIn < 10) {
      f();
    } else {
      setTimeout(f, expiresIn * 1000);
    }
  };

  const submitLogin = loginForm => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyBlYqerYjRa8ZHgymkwGL7pcgNhtv_l-Qw`,
          {
            email: loginForm.email,
            password: loginForm.password,
            returnSecureToken: true
          }
        )
        .then(res => {
          const { idToken, localId, refreshToken, expiresIn } = res.data;
          const expiryDate = new Date(new Date().getTime() + expiresIn * 1000);
          // rememberMe obsahuje hodnotu z closure! tu to nieje kriticke
          login({
            idToken,
            localId,
            refreshToken,
            expiryDate,
            rememberMe: loginForm.rememberMe
          });
          refreshTokenAfterExpiration(
            expiresIn,
            refreshToken,
            loginForm.rememberMe
          );
          resolve({ idToken, errors: false });
        })
        .catch(err => {
          // console.log(err.response.data.error);
          reject({ idToken: null, errors: true });
        });
    });
  };

  useEffect(() => {
    // Logout user when auth data are invalid
    axios.interceptors.response.use(
      res => res,
      err => {
        console.log(err.response.data.error);
        if (err.response.status && err.response.status === 401) {
          logout();
        }
        return Promise.reject(err);
      }
    );
  }, []);

  useEffect(() => {
    // Check Local Storage and Log in User
    const idToken = localStorage.getItem("tkn");
    if (idToken) {
      const expiryDate = new Date(localStorage.getItem("expdt"));
      const refreshToken = localStorage.getItem("rfrtkn");
      const localId = localStorage.getItem("uid");
      if (expiryDate > new Date()) {
        login({ idToken, localId, refreshToken, expiryDate, rememberMe: true });
        const expiresIn = (expiryDate.getTime() - new Date().getTime()) / 1000;
        refreshTokenAfterExpiration(expiresIn, refreshToken, true);
      } else {
        refreshTokenAfterExpiration(1, refreshToken, true);
        getEmail(localId, idToken, true);
      }
    } else {
      logout();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        localId: auth.localId,
        idToken: auth.idToken,
        refreshToken: auth.refreshToken,
        expiryDate: auth.expiryDate,
        email,
        login,
        logout,
        refreshingToken,
        refreshTokenAfterExpiration,
        submitLogin
      }}
    >
      <Checklist />
    </AuthContext.Provider>
  );
};

export default App;
