import React, { useState, useContext, useCallback } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import axios from "axios";
import AuthContext from "../../context/auth";

const passwordModal = props => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState([]);
  const [changingPassword, setChangingPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const auth = useContext(AuthContext);

  const submitPasswordChange = useCallback(() => {
    if (!passwordValid()) {
      checkforErrors();
      return;
    }
    setChangingPassword(true);
    const rememberMe = localStorage.getItem("tkn") ? true : false;
    auth
      .submitLogin({ email: auth.email, password: oldPassword, rememberMe })
      .then(res => {
        axios
          .post(
            `https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyBlYqerYjRa8ZHgymkwGL7pcgNhtv_l-Qw`,
            {
              idToken: res.idToken,
              password: newPassword,
              returnSecureToken: false
            }
          )
          .then(res => {
            setPasswordError([]);
            setChangingPassword(false);
            setPasswordChanged(true);
          })
          .catch(err => {
            // console.log(err.response.data.error);
            setPasswordError(["Neznáma chyba pri zmene hesla."]);
            setChangingPassword(false);
          });
      })
      .catch(err => {
        setPasswordError(["Staré heslo je neplatné. Skúste znova."]);
        setChangingPassword(false);
      });
  }, [newPassword, confirmPassword, oldPassword]);

  const modalClosedHandler = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    props.setPasswordModalOpen(false);
    setPasswordChanged(false);
    setPasswordError([]);
  };

  const passwordValid = () => {
    return (
      oldPassword &&
      newPassword &&
      confirmPassword === newPassword &&
      newPassword.length > 5
    );
  };

  const checkforErrors = () => {
    const errors = [];
    if (!oldPassword || !newPassword || !confirmPassword) {
      errors.push("Prosím vyplňťe všetky polia.");
    }
    if (newPassword !== confirmPassword) {
      errors.push("Nové heslo sa nezhoduje.");
    }
    if (newPassword.length < 6) {
      errors.push("Dĺžka hesla musí byť minimálne 6 znakov.");
    }
    setPasswordError(errors);
  };

  return (
    <React.Fragment>
      {passwordChanged ? (
        <Modal size={"mini"} open={props.show} onClose={modalClosedHandler}>
          <Modal.Header>Zmena hesla</Modal.Header>
          <Modal.Content>
            <p style={{ textAlign: "center" }}>Heslo bolo úspešne zmenené.</p>
          </Modal.Content>
          <Modal.Actions style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={modalClosedHandler}>Zavrieť</Button>
          </Modal.Actions>
        </Modal>
      ) : (
        <Modal size={"mini"} open={props.show} onClose={modalClosedHandler}>
          <Modal.Header>Zmena hesla</Modal.Header>
          <Modal.Content>
            <Form onSubmit={submitPasswordChange}>
              <Form.Input
                fluid
                placeholder="Staré heslo"
                type="password"
                onChange={event => setOldPassword(event.target.value)}
                value={oldPassword}
              />
              <Form.Input
                fluid
                placeholder="Nové heslo"
                type="password"
                onChange={event => setNewPassword(event.target.value)}
                value={newPassword}
              />
              <Form.Input
                fluid
                placeholder="Potvrdenie hesla"
                type="password"
                onChange={event => setConfirmPassword(event.target.value)}
                value={confirmPassword}
              />
              <input
                type="submit"
                id="submitPasswordForm"
                style={{ display: "none" }}
              />
            </Form>
            {passwordError.length > 0 &&
              passwordError.map((err, idx) => (
                <p key={idx} style={{ color: "red", textAlign: "center" }}>
                  {err}
                </p>
              ))}
          </Modal.Content>
          <Modal.Actions
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button onClick={modalClosedHandler}>Zrušiť</Button>
            <Button
              primary
              loading={changingPassword}
              onClick={() => {
                document.getElementById("submitPasswordForm").click();
              }}
            >
              Uložiť
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default passwordModal;
