import React from "react";

const auth = React.createContext({
  idToken: "",
  localId: "",
  refreshToken: "",
  expiryDate: "",
  email: "",
  login: () => {},
  logout: () => {},
  refreshingToken: false,
  refreshTokenAfterExpiration: () => {},
  loggingIn: false,
  setLoggingIn: () => {}
});

export default auth;
