import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import classes from "./checklist.module.css";
import AuthContext from "../../context/auth";
import {
  Container,
  Header,
  Segment,
  Input,
  List,
  Button,
  Icon,
  Loader,
  Dimmer
} from "semantic-ui-react";
import Navbar from "../../components/navbar/navbar";
import LoginModal from "../../components/loginModal/loginModal";
import PasswordModal from "../../components/passwordModal/passwordModal";
import ChooseChecklistModal from "../../components/chooseChecklistModal/chooseChecklistModal";
import ChecklistHeading from "../../components/checklistHeading/checklistHeading";

const Checklist = props => {
  const [items, setItems] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState("");
  const [inputEmpty, setInputEmpty] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [itemsHistory, setItemsHistory] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [saving, setSaving] = useState(false);
  const [keys, setKeys] = useState([]);
  const [deletedItemKey, setDeletedItemKey] = useState("");
  const [clearHistoryLoading, setClearHistoryLoading] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [checklistModalOpen, setChecklistModalOpen] = useState(false);
  const [createEmptyChecklist, setCreateEmptyChecklist] = useState(false);
  const [headerInputText, setHeaderInputText] = useState("");
  const [loadingItems, setLoadingItems] = useState(true);
  const [isNewBlankChecklist, setIsNewBlankChecklist] = useState(false);
  const [deletingChecklist, setDeletingChecklist] = useState(null);

  const auth = useContext(AuthContext);

  useEffect(() => {
    // console.log(itemsHistory);
  }, [itemsHistory]);

  useEffect(() => {
    if (auth.localId) {
      axios
        .get(
          `https://shopping-list-7d7ba.firebaseio.com/checklists/${auth.localId}.json?auth=${auth.idToken}`
        )
        .then(res => {
          const checklists = [];
          for (const key in res.data) {
            checklists.push({
              id: key,
              name: res.data[key].name,
              selected: res.data[key].selected
            });
          }
          if (checklists.length > 0) {
            const checklistsPlusPriorityItems = [
              ...checklists,
              {
                id: "priority",
                name: "Dôležité",
                selected: false
              }
            ];
            setChecklists(checklistsPlusPriorityItems);
            const selected = checklistsPlusPriorityItems.find(
              el => el.selected === true
            );
            if (selected) {
              setSelectedChecklist(selected.id);
            } else {
              setSelectedChecklist(checklistsPlusPriorityItems[0].id);
            }
          } else {
            setCreateEmptyChecklist(true);
          }
        })
        .catch(err => {
          // console.log(err);
        });
      axios
        .get(
          `https://shopping-list-7d7ba.firebaseio.com/items-history/${auth.localId}.json?auth=${auth.idToken}`
        )
        .then(res => {
          const results = [];
          for (const key in res.data) {
            results.push({
              key,
              content: res.data[key].content
            });
          }
          setItemsHistory(results);
        })
        .catch(err => {
          // console.log(err);
        });
    }
  }, [auth.localId]);

  useEffect(() => {
    if (selectedChecklist && auth.idToken) {
      setLoadingItems(true);
      setItems([]);
      // Get checklist heading text
      const checklistHeading = checklists.find(
        checklist => checklist.id === selectedChecklist
      );
      if (checklistHeading) {
        setHeaderInputText(checklistHeading.name);
      }
      // Get checklist items
      if (selectedChecklist === "priority") {
        axios
          .get(
            `https://shopping-list-7d7ba.firebaseio.com/items/${auth.localId}.json?auth=${auth.idToken}`
          )
          .then(res => {
            const prioChecklistItems = [];
            for (const checklistKey in res.data) {
              for (const itemKey in res.data[checklistKey]) {
                if (res.data[checklistKey][itemKey].priority) {
                  prioChecklistItems.push({
                    key: itemKey,
                    content: res.data[checklistKey][itemKey].content,
                    priority: res.data[checklistKey][itemKey].priority,
                    deleting: false,
                    originChecklist: checklistKey
                  });
                }
              }
            }
            if (prioChecklistItems.length > 0) {
              setItems(prioChecklistItems);
            }
            setLoadingItems(false);
            setIsNewBlankChecklist(false);
          })
          .catch(err => {
            setLoadingItems(false);
            setIsNewBlankChecklist(false);
          });
      } else {
        axios
          .get(
            `https://shopping-list-7d7ba.firebaseio.com/items/${auth.localId}/${selectedChecklist}.json?auth=${auth.idToken}`
          )
          .then(res => {
            const checklistItems = [];
            for (const itemKey in res.data) {
              checklistItems.push({
                key: itemKey,
                content: res.data[itemKey].content,
                priority: res.data[itemKey].priority,
                deleting: false
              });
            }
            if (checklistItems.length > 0) {
              setItems(checklistItems);
            }
            setLoadingItems(false);
            setIsNewBlankChecklist(false);
          })
          .catch(err => {
            setLoadingItems(false);
            setIsNewBlankChecklist(false);
          });
      }
    } else {
      if (createEmptyChecklist) {
        createChecklist();
      }
    }
  }, [selectedChecklist, auth, createEmptyChecklist]);

  useEffect(() => {
    if (inputValue) {
      const searchResults = search(inputValue);
      if (
        searchResults.length === 1 &&
        searchResults[0].content === inputValue
      ) {
        setSearchResults([]);
      } else {
        setSearchResults(searchResults);
      }
    } else if (!inputValue && searchResults.length > 0) {
      setSearchResults([]);
    }
  }, [inputValue]);

  useEffect(() => {
    updateKey(keys[0], keys[1]);
  }, [keys]);

  useEffect(() => {
    const updatedItems = items.filter(item => item.key !== deletedItemKey);
    setItems(updatedItems);
  }, [deletedItemKey]);

  const onInputChange = event => {
    if (inputEmpty) {
      setInputEmpty(false);
    }
    setInputValue(event.target.value);
  };

  const updateKey = (oldKey, newKey) => {
    const itemsCopy = [...items];
    for (const idx in itemsCopy) {
      if (itemsCopy[idx].key === oldKey) {
        itemsCopy[idx] = { ...itemsCopy[idx], key: newKey };
      }
    }
    setItems(itemsCopy);
  };

  const itemAdded = (itemName = inputValue) => {
    // ak je itemName parameter undefined pouzije default hodnotu z inputValue
    if (itemName) {
      const newItem = {
        key: Math.random()
          .toString(36)
          .substr(2, 5),
        content: itemName,
        deleting: false
      };
      axios
        .post(
          `https://shopping-list-7d7ba.firebaseio.com/items/${auth.localId}/${selectedChecklist}.json?auth=${auth.idToken}`,
          {
            content: itemName,
            priority: false
          }
        )
        .then(res => {
          // console.log("success");
          setKeys([newItem.key, res.data.name]);
          setSaving(false);
        })
        .catch(err => {
          // console.log(err);
        });
      setItems(items.concat(newItem));
      setInputValue("");
      setSearchResults([]);
      setSaving(true);
    } else {
      setInputEmpty(true);
    }
  };

  const itemRemoved = (iKey, itemik, originChecklistId) => {
    const checklistId =
      selectedChecklist === "priority" ? originChecklistId : selectedChecklist;
    axios
      .delete(
        `https://shopping-list-7d7ba.firebaseio.com/items/${auth.localId}/${checklistId}/${iKey}.json?auth=${auth.idToken}`
      )
      .then(res => {
        setDeletedItemKey(iKey);
      })
      .catch(err => {
        // console.log(err);
      });
    const updatedItems = [...items];
    for (const idx in updatedItems) {
      if (updatedItems[idx].key === iKey) {
        updatedItems[idx] = { ...updatedItems[idx], deleting: true };
      }
    }
    if (search(itemik.content).length === 0) {
      axios.post(
        `https://shopping-list-7d7ba.firebaseio.com/items-history/${auth.localId}.json?auth=${auth.idToken}`,
        itemik
      );
      setItemsHistory(itemsHistory.concat(itemik));
    }
    setItems(updatedItems);
  };

  const setPriority = (iKey, originChecklistId) => {
    let prio;
    const checklistId =
      selectedChecklist === "priority" ? originChecklistId : selectedChecklist;
    const updatedItems = [...items];
    for (const idx in updatedItems) {
      if (updatedItems[idx].key === iKey) {
        prio = !updatedItems[idx].priority;
        updatedItems[idx] = { ...updatedItems[idx], priority: prio };
      }
    }
    axios
      .patch(
        `https://shopping-list-7d7ba.firebaseio.com/items/${auth.localId}/${checklistId}/${iKey}.json?auth=${auth.idToken}`,
        { priority: prio }
      )
      .then(res => {
        return;
      })
      .catch(err => {
        // console.log(err);
      });
    setItems(updatedItems);
  };

  const search = param => {
    let results = [];
    for (const item of itemsHistory) {
      if (item.content.toLowerCase().indexOf(param.toLowerCase()) === 0) {
        results.push(item);
      }
    }
    return results;
  };

  const clearHistoryHandler = () => {
    axios
      .delete(
        `https://shopping-list-7d7ba.firebaseio.com/items-history/${auth.localId}.json?auth=${auth.idToken}`
      )
      .then(res => {
        setClearHistoryLoading(false);
      })
      .catch(err => {
        // console.log(err);
      });
    setClearHistoryLoading(true);
    setItemsHistory([]);
    setSearchResults([]);
  };

  const itemsList = items.map(item => {
    const itemContentStyle = {
      lineHeight: "2.3em"
    };
    if (item.priority) {
      itemContentStyle.color = "red";
      itemContentStyle.fontWeight = "bold";
    }

    return (
      <List.Item key={item.key} className={"dimmer-scope"}>
        {item.deleting ? (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        ) : null}
        <List.Content floated="right">
          <Button
            negative
            onClick={() => itemRemoved(item.key, item, item.originChecklist)}
          >
            Zmaž
          </Button>
        </List.Content>
        <List.Content
          style={itemContentStyle}
          onClick={() => setPriority(item.key, item.originChecklist)}
        >
          {item.content}
        </List.Content>
      </List.Item>
    );
  });

  const searchResultsList = searchResults.map(item => (
    <List.Item key={item.key} onClick={() => itemAdded(item.content)}>
      <List.Content>{item.content}</List.Content>
    </List.Item>
  ));

  const saveHeading = headerText => {
    axios
      .patch(
        `https://shopping-list-7d7ba.firebaseio.com/checklists/${auth.localId}/${selectedChecklist}.json?auth=${auth.idToken}`,
        { name: headerText }
      )
      .then(res => {
        return;
      })
      .catch(err => {
        // console.log(err);
      });

    setChecklists(prevState => {
      const updatedChecklists = prevState.map(checklist => {
        if (checklist.id === selectedChecklist) {
          return { ...checklist, name: headerText };
        }
        return checklist;
      });
      return updatedChecklists;
    });
  };

  const createChecklist = () => {
    setIsNewBlankChecklist(true);
    axios
      .post(
        `https://shopping-list-7d7ba.firebaseio.com/checklists/${auth.localId}.json?auth=${auth.idToken}`,
        { name: "Bez názvu", selected: true }
      )
      .then(res => {
        setCreateEmptyChecklist(false);
        setSelectedChecklist(res.data.name);
        setChecklists(prev => [
          ...prev,
          { id: res.data.name, name: "Bez názvu", selected: true }
        ]);
        setHeaderInputText("Bez názvu");
      })
      .catch(err => {
        console.log(err);
      });
  };

  const deleteChecklist = id => {
    if (id === selectedChecklist) {
      console.log("Active checklist cannot be deleted");
    } else {
      setDeletingChecklist(id);
      axios
        .delete(
          `https://shopping-list-7d7ba.firebaseio.com/checklists/${auth.localId}/${id}.json?auth=${auth.idToken}`
        )
        .then(res => {
          const updatedChecklists = checklists.filter(el => id !== el.id);
          setChecklists(updatedChecklists);
          setDeletingChecklist(null);
        })
        .catch(err => {
          console.error(err);
          setDeletingChecklist(null);
        });
    }
  };

  const setSelectedInFirebase = id => {
    if (id !== "priority") {
      axios
        .patch(
          `https://shopping-list-7d7ba.firebaseio.com/checklists/${auth.localId}/${id}.json?auth=${auth.idToken}`,
          { selected: true }
        )
        .then(res => {
          if (selectedChecklist !== "priority") {
            axios.patch(
              `https://shopping-list-7d7ba.firebaseio.com/checklists/${auth.localId}/${selectedChecklist}.json?auth=${auth.idToken}`,
              { selected: false }
            );
          }
        })
        .catch(err => {
          // console.log(err);
        });
    }
    setSelectedChecklist(id);
    setChecklistModalOpen(false);
  };

  return (
    <React.Fragment>
      <Container text style={{ paddingBottom: "30px" }}>
        <Navbar
          createChecklist={createChecklist}
          clearItems={setItems}
          setPasswordModalOpen={setPasswordModalOpen}
          changeChecklistModal={() => setChecklistModalOpen(prev => !prev)}
        />
        <Segment.Group raised>
          <Segment textAlign="center" className={saving ? "saving" : null}>
            <Header as="h1" textAlign="center" icon>
              <Icon name="clipboard outline" style={{ fontSize: "2em" }} />
            </Header>
            <ChecklistHeading
              headerInputText={headerInputText}
              setHeaderInputText={setHeaderInputText}
              saveHeading={saveHeading}
              selectedChecklist={selectedChecklist}
              disabled={selectedChecklist === "priority" ? true : false}
            />
            <Input
              size="medium"
              error={inputEmpty}
              placeholder="Pridaj položku..."
              className={[classes.inputMargin, "inputItem"].join(" ")}
              loading
              action={{
                color: "blue",
                labelPosition: "right",
                icon: "save",
                content: "Pridaj",
                onClick: saving ? null : () => itemAdded()
              }}
              onChange={onInputChange}
              value={inputValue}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  itemAdded();
                }
              }}
            />
            <Button
              onClick={clearHistoryHandler}
              negative
              loading={clearHistoryLoading}
              className={classes.clearHistoryBtn}
            >
              Zmaž históriu
            </Button>
            {searchResults.length > 0 ? (
              <List
                divided
                relaxed
                selection
                size="large"
                className={classes.historyList}
              >
                {searchResultsList}
              </List>
            ) : null}
          </Segment>
          {itemsList.length > 0 ? (
            <Segment>
              <List
                divided
                verticalAlign="middle"
                size="large"
                className={classes.itemsList}
              >
                {itemsList}
              </List>
            </Segment>
          ) : null}
        </Segment.Group>
        {loadingItems && !isNewBlankChecklist ? (
          <p className={classes.loadingText}>Načítavam záznamy...</p>
        ) : null}
        {!loadingItems && itemsList.length === 0 ? (
          <p className={classes.loadingText}>Checklist je prázdny...</p>
        ) : null}
      </Container>
      <Dimmer active={auth.refreshingToken} inverted>
        <Loader inverted />
      </Dimmer>
      <LoginModal />
      <PasswordModal
        show={passwordModalOpen}
        setPasswordModalOpen={setPasswordModalOpen}
      />
      <ChooseChecklistModal
        setSelectedInFirebase={setSelectedInFirebase}
        deletingChecklist={deletingChecklist}
        modalOpened={checklistModalOpen}
        checklists={checklists}
        selectedChecklist={selectedChecklist}
        deleteChecklist={deleteChecklist}
      />
    </React.Fragment>
  );
};

export default Checklist;
