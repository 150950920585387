import React, { useRef, useEffect, useState } from "react";
import classes from "./checklistHeading.module.css";
import { Input } from "semantic-ui-react";

const checklistHeading = props => {
  const [changeStart, setChangeStart] = useState(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.selectedChecklist && changeStart) {
      // Bind the event listener when heading was changed
      document.addEventListener("mousedown", handleClickOutside);
    }
  }, [props.selectedChecklist, changeStart]);

  const handleClickOutside = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      // inputRef hack because we need current value of input field
      props.saveHeading(inputRef.current.props.value);
      // changing of heading finished
      setChangeStart(false);
      // Unbind event listener
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const onHeaderInputChange = e => {
    props.setHeaderInputText(e.target.value);
    // changing of heading started
    if (!changeStart) {
      setChangeStart(true);
    }
  };
  return (
    <div className={classes.headerInput} ref={wrapperRef}>
      <Input
        value={props.headerInputText}
        onChange={onHeaderInputChange}
        ref={inputRef}
        disabled={props.disabled}
      />
    </div>
  );
};

export default checklistHeading;
