import React from "react";
import { Icon, Modal, Header, List, Dimmer, Loader } from "semantic-ui-react";

const chooseChecklistModal = props => {
  const changeChecklist = (e, id) => {
    if (e.target.tagName !== "I" && props.deletingChecklist === null) {
      props.setSelectedInFirebase(id);
    }
  };

  const prioChecklistIndex = props.checklists.findIndex(
    e => e.id === "priority"
  );

  // SWAP priority checklist to the top
  let checklistsWithPrioAtStart;
  if (props.checklists.length > 0) {
    checklistsWithPrioAtStart = [...props.checklists];
    const startChecklistTemp = checklistsWithPrioAtStart[0];
    checklistsWithPrioAtStart[0] =
      checklistsWithPrioAtStart[prioChecklistIndex];
    checklistsWithPrioAtStart[prioChecklistIndex] = startChecklistTemp;
  }

  return (
    <Modal size={"mini"} open={props.modalOpened} onClose={props.modalClosed}>
      <Header icon="folder open" content="Prepni checklist" />
      <Modal.Content>
        <List divided selection verticalAlign="middle">
          {props.checklists.length > 0 &&
            checklistsWithPrioAtStart.map(checklist => {
              let headerStyle = { display: "inline-block" };
              if (checklist.id === "priority") {
                headerStyle = { ...headerStyle, color: "red" };
              }
              return (
                <List.Item
                  className={"dimmer-scope"}
                  style={{
                    padding: "0.7rem 0.5rem 0.7rem 0.5rem",
                    fontSize: "16px",
                    display: "flex"
                  }}
                  key={checklist.id}
                  onClick={e => changeChecklist(e, checklist.id)}
                >
                  {props.deletingChecklist === checklist.id ? (
                    <Dimmer active inverted>
                      <Loader inverted />
                    </Dimmer>
                  ) : null}
                  <div style={{ flexBasis: "26px" }}>
                    {checklist.id === props.selectedChecklist && (
                      <Icon name="thumbtack" />
                    )}
                  </div>
                  <List.Content
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexBasis: "calc(100% - 26px)"
                    }}
                  >
                    <List.Header style={headerStyle}>
                      {checklist.name}
                    </List.Header>
                    {checklist.id !== "priority" && (
                      <Icon
                        name="delete"
                        style={{ color: "red" }}
                        onClick={() => props.deleteChecklist(checklist.id)}
                      />
                    )}
                  </List.Content>
                </List.Item>
              );
            })}
        </List>
      </Modal.Content>
    </Modal>
  );
};

export default chooseChecklistModal;
